import Button = require("Everlaw/UI/Button");
import Dom = require("Everlaw/Dom");
import SbFreeProject = require("Everlaw/Context/SbFree/Project");

export function createNewProjectButton() {
    const createNewButton = Dom.byId("sb-free-create-new-project");
    Button.newAddition({
        label: "Create new project",
        onClick: () => {
            SbFreeProject.createNewDialog({
                onSuccess: (project) => project.goto("home"),
            });
        },
        parent: createNewButton,
    });
    Dom.show(createNewButton);
}
